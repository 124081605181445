import React, { useCallback } from 'react';
import { IconButton, Card, CardActions, CardContent } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
//import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FileIcon from '../../../../../assets/icon/fileIcon.svg';
import { Typography } from 'src/components/Generic/StyledComponent';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MutualAid from './../../../../../services/api/mutualAid';
import useCredentials from 'src/hooks/useCredentials';
import { getPdf } from './../../../../Appointment/PrescriptionPreview/index';
import { Fetch } from 'src/services/middleware';

export default function BasicCard({
  data,
  viewDocument,
  isApprovedBtnPressed,
  isEditable,
  isApproved,
  onOpenInNew
}) {
  const getDocIconBasedOnStatus = useCallback((status) => {
    if (isApproved) {
      return <CheckCircleIcon style={{ color: 'rgba(33, 150, 83, 1)' }} />;
    } else {
      switch (status) {
        case 'APPROVE':
          return <CheckCircleIcon style={{ color: 'rgba(33, 150, 83, 1)' }} />;
        case 'REJECT':
          return <CancelIcon style={{ color: 'rgba(242, 69, 61, 1)' }} />;
        default:
          return null;
      }
    }
  }, []);
  const { accessToken } = useCredentials();

  const download = async () => {
    await MutualAid.getDocument({ accessToken, data: { docId: data.id } }).then(
      async (res) => {
        let response =
          res?.data?.encodedResource &&
          `data:${res.data.mimeType};base64,${res.data.encodedResource}`;
        if (response && response.length > 0) {
          if (res.data.mimeType === 'application/pdf') {
            // For PDF files
            getPdf(response).then((res) => {
              const blob = new Blob([res], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'file.pdf'); // 'file.pdf'
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
          } else if (res.data.mimeType.startsWith('image/')) {
            // For images files
            const blob = await Fetch(response).then((r) => r.blob());
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg'); //  'image.jpg'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error('Unsupported file type');
          }
        }
      }
    );
  };

  // const OpenInNew = async () => {
  //   await MutualAid.getDocument({ accessToken, data: { docId: data.id } }).then(
  //     (res) => {
  //       let response =
  //         res?.data?.encodedResource &&
  //         `data:${res.data.mimeType};base64,${res.data.encodedResource}`;
  //       if (response && response.length > 0) {
  //         getPdf(response).then((res) => {
  //           let blobUrl = URL.createObjectURL(res);
  //           window.open(blobUrl);
  //         });
  //       }
  //     }
  //   );
  // };

  return (
    <Card
      style={{
        minWidth: 184,
        margin: 5,
        borderColor:
          !isApproved &&
          isApprovedBtnPressed &&
          data.status !== 'APPROVE' &&
          'red'
      }}
      variant="outlined"
    >
      <CardContent>
        <img src={FileIcon} alt="fileIcon" />
        <Typography
          variant="subtitle2"
          style={{ marginTop: 14, fontWeight: 500 }}
          color="rgba(51, 51, 51, 1)"
        >
          {data.documentName}
        </Typography>
        <Typography variant="caption" style={{ marginTop: 12 }}>
          {data.documentType}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton
          style={{ padding: 5 }}
          onClick={() => viewDocument(data)}
          size="large"
        >
          <VisibilityOutlinedIcon />
        </IconButton>
        {onOpenInNew && (
          <IconButton
            style={{ padding: 5 }}
            onClick={() => onOpenInNew(data)}
            size="large"
          >
            <OpenInNewIcon />
          </IconButton>
        )}
        <IconButton
          style={{ padding: 5, marginRight: 8 }}
          onClick={() => download(data)}
          size="large"
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
        {data.status && getDocIconBasedOnStatus(data.status)}
      </CardActions>
    </Card>
  );
}
