import React, { useEffect, useCallback } from 'react';
import { IconButton, Modal, Grid, AppBar, Toolbar, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '../../../../../components/Generic/StyledComponent';
// import Modal from 'src/components/common/modal';
import { toast } from 'react-toastify';
import { useGetDocument, usePostReviewDocument } from './hooks';
import LoaderButton from '../loaderButton';
import PdfViewer from 'src/components/common/pdfViewer/PdfViewer';
import useMobileDetect from 'src/useMobileDetect';
import LoadingDetails from 'src/components/common/shimmerLoader/LoadingDetails';
import { PRIMARY_COLOR } from 'src/services/data';
import CustomPdfViewer from 'src/components/common/pdfViewer/CustomPdfViewer';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    height: '100%',
    flexGrow: 1
  },
  header: {
    backgroundColor: 'rgba(255, 255, 255, 1)'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

function DocumentModal({
  open,
  handleClose,
  selectedDocument,
  onDocApprove,
  isEditable,
  isApproved
}) {
  const classes = useStyles();
  const { state, request } = useGetDocument({
    onFail: (errorObject) => {
      toast.error(errorObject.message);
      handleClose();
    }
  });
  const { state: reviewState, request: reviewRequest } = usePostReviewDocument({
    onFail: (errorObject) => {
      toast.error(errorObject.message);
    },
    onSuccess: ({ data }) => {
      onDocApprove((prevState) => {
        const docs = prevState.documents[selectedDocument.documentType].filter(
          (doc) => doc.id !== selectedDocument.id
        );
        const newData = [...docs, data];
        return {
          ...prevState,
          documents: {
            ...prevState.documents,
            [selectedDocument.documentType]: newData
          }
        };
      });
      toast.success(data.message);
      handleClose();
    }
  });

  const reviewerAction = useCallback(
    (action) => reviewRequest({ action, docId: selectedDocument.id }),
    [reviewRequest, selectedDocument.id]
  );

  useEffect(() => {
    request({ docId: selectedDocument.id });
  }, [request, selectedDocument.id]);

  const { isMobile } = useMobileDetect();
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container className={classes.backdrop}>
        <AppBar className={classes.header}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              onClick={handleClose}
              size="large"
            >
              <ArrowBackIcon sx={{ color: PRIMARY_COLOR }} />{' '}
              <span
                style={{ color: PRIMARY_COLOR, fontSize: 14, marginLeft: 4 }}
              >
                Back
              </span>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {selectedDocument.documentName}
            </Typography>
            {!isApproved &&
              isEditable &&
              selectedDocument.status == 'PENDING' && (
                <>
                  <LoaderButton
                    variant="contained"
                    color="error"
                    startIcon={<CloseIcon />}
                    style={{
                      marginRight: 10,
                      textTransform: 'capitalize'
                    }}
                    onClick={() => reviewerAction('REJECT')}
                    disabled={state.loading}
                    loading={reviewState.loading}
                  >
                    Reject
                  </LoaderButton>
                  <LoaderButton
                    variant="contained"
                    startIcon={<CheckIcon />}
                    style={{
                      textTransform: 'capitalize',
                      backgroundColor: 'rgba(76, 175, 80, 1)'
                    }}
                    onClick={() => reviewerAction('APPROVE')}
                    disabled={state.loading}
                    loading={reviewState.loading}
                  >
                    Approve
                  </LoaderButton>
                </>
              )}
          </Toolbar>
        </AppBar>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            // flexDirection: 'row',
            paddingLeft: 10,
            paddingRight: 10,
            zIndex: 1
          }}
        >
          {/* <IconButton style={{ backgroundColor: '#fff' }}>
            <ChevronLeftIcon fontSize="large" />
          </IconButton> */}
          <Paper
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 10,
              marginTop: 75,
              marginBottom: 10
            }}
          >
            {!state.loading && state.data && state.data.encodedResource ? (
              state.data.mimeType === 'application/pdf' ? (
                <CustomPdfViewer
                  pdf={`data:${state.data.mimeType};base64,${state.data.encodedResource}`}
                />
              ) : (
                <img
                  src={`data:${state.data.mimeType};base64,${state.data.encodedResource}`}
                  alt="document-preview"
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 105px)',
                    objectFit: 'contain'
                  }}
                />
              )
            ) : (
              <LoadingDetails />
            )}
          </Paper>
          {/* <IconButton style={{ backgroundColor: '#fff' }}>
            <ChevronRightIcon fontSize="large" />
          </IconButton> */}
        </Grid>
      </Grid>
    </Modal>
  );
}

export default DocumentModal;
