import React from 'react';
import { Backdrop, CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFilter } from 'src/context/FilterContext';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 11111,
    color: '#fff',
    backdropFilter: 'blur(-10px)'
  },
  loaderContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300, // Ensure the loader stays on top
    backdropFilter: 'blur(-3px)' // Blur effect
  }
}));

const FullPageLoader = () => {
  const classes = useStyles();
  const { loading } = useFilter();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <Box className={classes.loaderContainer}>
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};

export default FullPageLoader;
