import * as XLSX from 'xlsx';

export const USER_ROLES = {
  Doctor: 'Doctor',
  IO_Reviewer: 'IO_Reviewer',
  IO_Reviewer_Update: 'IO_Reviewer_Update',
  DOC_Reviewer: 'DOC_Reviewer',
  SDOC_Reviewer: 'SDOC_Reviewer',
  FO_Reviewer: 'FO_Reviewer',
  Patient: 'Patient',
  ADMIN_REVIEWER: 'ADMIN_REVIEWER',
  Call_Logs: 'Call_Logs',
  OVERDUE_Reviewer: 'OVERDUE_Reviewer',
  Higher_Level_Reviewer: 'higher_level_reviewer',
  Claim_Manager_Reviewer: 'claim_manager_reviewer',
  Claim_Download_Upload: 'claim_download_upload',
  Hindi_Prescription: 'hindi_prescription',
  Insurance_Policy_View: 'insurance_policy_view',
  Claim_Manager: 'claim_manager',
  External_Claim: 'external_claim'
};

export const sequenceType = {
  Ascending: 'ASC',
  Descending: 'DESC'
};

export const dataToXLSX = (data) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const xlsxBuffer = XLSX.write(workbook, {
    type: 'buffer',
    bookType: 'xlsx'
  });

  return xlsxBuffer;
};

export const handleDownload = (data) => {
  const xlsxData = dataToXLSX(data);
  const blob = new Blob([xlsxData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = 'table_data.xlsx';
  downloadLink.click();
  URL.revokeObjectURL(downloadLink.href);
};
