// FilterContext.js
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import { USER_ROLES, sequenceType } from 'src/constants';
import { hasRole } from 'src/containers/MutualAid/claimDetails';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    mobileNumber: '',
    claimId: '',
    resellerName: '',
    loanCode: '',
    startDate: '',
    endDate: '',
    officerStatus: '',
    sequencedType: sequenceType.Ascending,
    page: 0,
    size: 20,
    status: hasRole(USER_ROLES.OVERDUE_Reviewer) ? 'ALL' : 'IN_PROGRESS'
  });
  const [allClaimFilters, setAllClaimFilters] = useState({
    mobileNumber: '',
    claimId: '',
    resellerName: '',
    loanCode: '',
    startDate: '',
    endDate: '',
    officerStatus: '',
    sequencedType: sequenceType.Ascending,
    page: 0,
    size: 20,
    status: hasRole(USER_ROLES.OVERDUE_Reviewer) ? 'ALL' : 'IN_PROGRESS'
  });
  const [loading, setLoading] = useState(false);
  const externalFilterValue = {
    status: 'ALL',
    page: 0,
    size: 10,
    mobileNumber: '',
    loanCode: '',
    claimId: '',
    resellerName: '',
    sequencedType: 'ASC',
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };
  const [externalFilter, setExternalFilter] = useState(externalFilterValue);

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        allClaimFilters,
        setAllClaimFilters,
        loading,
        setLoading,
        externalFilter,
        setExternalFilter,
        externalFilterValue
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
