import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useMobileDetect from 'src/useMobileDetect';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Refresh, ZoomIn, ZoomOut } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';

export default function CustomPdfViewer(props) {
  const { pdf } = props;
  const { isMobile } = useMobileDetect();
  const maxScale = 1.8;
  const minScale = isMobile() ? 0.4 : 1.4;
  const [numPages, setNumPages] = useState(null);
  const [scaleValue, setScaleValue] = useState(isMobile() ? 0.4 : 1.4);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const zoomIn = () => {
    if (scaleValue < maxScale) setScaleValue(scaleValue + 0.1);
  };

  const zoomOut = () => {
    if (scaleValue > minScale) setScaleValue(scaleValue - 0.1);
  };

  const resetTransform = () => {
    setScaleValue(isMobile() ? 0.4 : 1.4);
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'gray',
          color: '#fff',
          padding: 4,
          gap: 6,
          top: 14,
          zIndex: 10,
          position: 'sticky'
        }}
      >
        <Tooltip title="zoom in">
          <ZoomIn
            onClick={() => zoomIn()}
            style={{ height: 25, width: 25, cursor: 'pointer' }}
          />
        </Tooltip>
        <Tooltip title="zoom out"></Tooltip>
        <ZoomOut
          onClick={() => zoomOut()}
          style={{ height: 25, width: 25, cursor: 'pointer' }}
        />
        <Tooltip title="reset">
          <Refresh
            onClick={() => resetTransform()}
            style={{ height: 25, width: 25, cursor: 'pointer' }}
          />
        </Tooltip>
      </div>
      <div
        style={{
          overflow: 'auto',
          maxWidth: isMobile() ? '' : '80vw',
          maxHeight: props?.splitScreenDoc ? '72vh' : '85vh'
        }}
        id="Pdf-Dialog"
      >
        <Document
          file={pdf}
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div
              style={{
                height: '200px',
                width: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              scale={scaleValue}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
    </div>
  );
}
