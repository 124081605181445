import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  IconButton,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField
} from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import LabelValue from '../labelValue';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@rsuite/icons/Edit';
import PlusIcon from '@rsuite/icons/Plus';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { getCredentials } from 'src/hooks/useCredentials';
import { MutualAid } from 'src/services/api';
import { fetchHospitalDetails } from 'src/redux/action/profile';
import Autocomplete from '@mui/material/Autocomplete';
import AddHospital from './AddHospital';
import { Field, Formik } from 'formik';
import { PRIMARY_COLOR } from 'src/services/data';
import { toast } from 'react-toastify';

function HospitalDetails({
  data: {
    hospitalDetails: { name, state, admissionDate, dischargeDate, hospitalId },
    claimDetails: {
      subClaimId,
      policyActualId,
      claimId,
      claimSlabType,
      policyStartDate
    }
  },
  isApproved,
  toggleApproved,
  isApprovedBtnPressed,
  isEditable,
  setIsReload,
  canEdit,
  disabled
}) {
  const [edit, setEdit] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const { accessToken } = getCredentials();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [claimDateApproveToggle, setClaimDateApproveToggle] = useState(false);
  const [editData, setEditData] = useState({
    claimId: claimId,
    name: name,
    hospitalId: hospitalId
  });
  const formikRef = useRef();
  const [validation, setValidation] = useState({
    claimId: false,
    name: false,
    hospitalId: false
  });
  const [claimDateValidation, setClaimDateValidation] = useState({
    startDate: false,
    endDate: false,
    claimSlabType: false
  });

  const hospitalApproveToggle = () => {
    if (!admissionDate || !dischargeDate) {
      toast.error('Please enter claim start and end date');
      setClaimDateApproveToggle(true);
    } else {
      setClaimDateApproveToggle(false);
      toggleApproved();
    }
  };

  const outSideOnChange = (name, value) => {
    setEditData({ ...editData, [name]: value });
    if (value) {
      setValidation({ ...validation, [name]: false });
    } else {
      setValidation({ ...validation, [name]: true });
    }
  };

  const fetchHospitalData = () => {
    fetchHospitalDetails().then((res) => {
      if (res) {
        var output = res.map((s) => {
          if (s.hasOwnProperty('name')) {
            s.label = s.name;
            delete s.name;
          }
          return s;
        });
        setHospitals(output);
      }
    });
  };

  useEffect(() => {
    fetchHospitalData();
  }, []);

  const save = async () => {
    if (isDisable()) {
      return;
    }
    const data = formikRef.current.values;

    if (editData.name) {
      await MutualAid.updateHospitalDetails(accessToken, editData).then(
        (res) => {
          setIsReload(true);
          setEdit(false);
        }
      );
    }
    handleSubmitClaimDate(data);
  };

  const saveNewHospital = async (data) => {
    const newData = {
      claimId: claimId,
      ...data
    };
    await MutualAid.updateHospitalDetails(accessToken, newData).then((res) => {
      setIsReload(true);
      setEdit(false);
      fetchHospitalData();
    });
  };

  const handleSubmitClaimDate = (data) => {
    const newData = {
      ...data,
      claimId: claimId,
      subClaimId: subClaimId,
      policyActualId: policyActualId
    };
    MutualAid.updateClaimDateDetails(newData).then((res) => {
      if (res?.code === 200) {
        setIsReload(true);
        setOpen(false);
      }
    });
  };

  const isDisable = () => {
    if (formikRef.current) {
      const data = formikRef.current.values;
      const { startDate, endDate, claimSlabType } = data;

      if (!startDate) {
        setClaimDateValidation((prev) => ({
          ...prev,
          startDate: 'Start date required'
        }));
        return true;
      } else {
        setClaimDateValidation((prev) => ({
          ...prev,
          startDate: ''
        }));
      }
      if (!endDate) {
        setClaimDateValidation((prev) => ({
          ...prev,
          endDate: 'End date required'
        }));
        return true;
      } else {
        setClaimDateValidation((prev) => ({
          ...prev,
          endDate: ''
        }));
      }
      if (!claimSlabType) {
        setClaimDateValidation((prev) => ({
          ...prev,
          endDate: 'Slab type required'
        }));
        return true;
      } else {
        setClaimDateValidation((prev) => ({
          ...prev,
          endDate: ''
        }));
      }
      if (!startDate || !endDate || !claimSlabType) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Paper style={{ padding: 16 }} variant="outlined">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        <Typography
          color="rgba(79, 79, 79, 1)"
          style={{
            fontSize: 18,
            color: isApprovedBtnPressed && !isApproved && 'red'
          }}
        >
          Hospital Details
        </Typography>
        <div style={{ display: 'flex' }}>
          {isEditable ? (
            <IconButton
              disabled={disabled}
              style={{ padding: 5 }}
              onClick={hospitalApproveToggle}
              size="large"
            >
              {isApproved ? (
                <CheckCircleIcon style={{ color: 'rgba(33, 150, 83, 1)' }} />
              ) : (
                <CheckCircleOutlineOutlinedIcon
                  style={{ color: isApprovedBtnPressed ? 'red' : null }}
                />
              )}
            </IconButton>
          ) : null}
          {isEditable && !edit && canEdit ? (
            <div style={{ marginTop: 8, marginLeft: 5 }}>
              <EditIcon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  marginLeft: 8,
                  color: 'blue'
                }}
                onClick={() => setEdit(!edit)}
              />
              <PlusIcon
                style={{ float: 'right', cursor: 'pointer', color: 'green' }}
                onClick={() => setOpen(!open)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {!edit ? (
        <LabelValue
          label="Hospital Name"
          editable={true}
          outSideEditData={editData.name}
          edittableOutSide={edit}
          insideIconNotVisible={true}
          name={'name'}
          value={name}
          validation={validation.name}
          outSideOnChange={outSideOnChange}
        />
      ) : (
        <>
          <label>Hospital Name</label>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={hospitals}
            value={name}
            open={inputValue.length > 4}
            style={{
              marginBottom: '-15px'
            }}
            onChange={(e, v) => {
              if (v) {
                setEditData({ ...editData, hospitalId: v.id });
                setValidation({ ...validation, name: false });
                setInputValue('');
              } else {
                setValidation({ ...validation, name: true });
              }
            }}
            renderInput={(params) => (
              <TextField
                onChange={(e) => setInputValue(e.target.value)}
                {...params}
              />
            )}
          />
        </>
      )}
      <LabelValue label="State" value={state} editable={false} />
      {!edit ? (
        <LabelValue
          label={
            <div
              style={{
                color: claimDateApproveToggle ? PRIMARY_COLOR : ''
              }}
            >
              Date of Admission-Discharge
            </div>
          }
          value={
            admissionDate && dischargeDate
              ? `${admissionDate} - ${dischargeDate}`
              : 'Admission and discharge date not found'
          }
        />
      ) : (
        <div style={{ marginTop: 30 }}>
          <Formik
            initialValues={{
              startDate: admissionDate,
              endDate: dischargeDate,
              claimSlabType: claimSlabType
            }}
            innerRef={formikRef}
            onSubmit={save}
          >
            {({ values }) => (
              <>
                <Grid container gap={1}>
                  <div style={{ width: '100%' }}>
                    <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                      Reimbursement Start Date
                    </Typography>

                    <Field
                      type="date"
                      value={values.startDate}
                      name="startDate"
                      component={CommonTextField}
                      minDate={admissionDate || policyStartDate}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      error={claimDateValidation?.startDate}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                      Reimbursement End Date
                    </Typography>

                    <Field
                      type="date"
                      value={values.endDate}
                      name="endDate"
                      component={CommonTextField}
                      minDate={values.startDate}
                      maxDate={new Date().toISOString().slice(0, 10)}
                      disabled={!values.startDate}
                      error={claimDateValidation?.endDate}
                    />
                  </div>

                  <Field
                    value={values.claimSlabType}
                    name="claimSlabType"
                    component={SelectField}
                    error={claimDateValidation?.claimSlabType}
                  />
                </Grid>
              </>
            )}
          </Formik>
        </div>
      )}
      {edit ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button type="submit" color="success" onClick={() => save()}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setEdit(!edit);
                setEditData({
                  state: state,
                  name: name,
                  admissionDate: admissionDate,
                  dischargeDate: dischargeDate
                });
                setValidation({
                  state: false,
                  name: false,
                  admissionDate: false,
                  dischargeDate: false
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <AddHospital open={open} setOpen={setOpen} callBack={saveNewHospital} />
    </Paper>
  );
}

export default HospitalDetails;

function CommonTextField({
  field: { onChange, value, name },
  form: { errors },
  label,
  type = 'text',
  minDate,
  maxDate,
  disabled,
  error
}) {
  return (
    <>
      <TextField
        name={name}
        label={label}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        type={type}
        error={error}
        inputProps={{
          min: minDate,
          max: maxDate,
          format: 'DD/MM/YYYY'
        }}
        disabled={disabled}
      />
      <FormHelperText
        style={{ marginBottom: '10px', marginTop: '-10px' }}
        error
      >
        {error}
      </FormHelperText>
    </>
  );
}

function SelectField({
  field: { onChange, value, name },
  form: { errors },
  error
}) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel sx={{ marginTop: 1 }} id="demo-simple-select-label">
          Claim Slab Type
        </InputLabel>
        <Select
          name={name}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Claim Slab Type"
          onChange={onChange}
        >
          <MenuItem value={'OPCASH'}>NORMAL</MenuItem>
          <MenuItem value={'ICU'}>ICU</MenuItem>
        </Select>
        <FormHelperText
          style={{ marginBottom: '10px', marginTop: '-10px' }}
          error
        >
          {error}
        </FormHelperText>
      </FormControl>
    </>
  );
}
