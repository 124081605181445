import { hasRole } from 'src/containers/MutualAid/claimDetails';
import { USER_ROLES } from 'src/constants';

export let client_access_key =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_KEY_${process.env.REACT_APP_MODE}`
  ];
export let client_access_secret =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_SECRET_${process.env.REACT_APP_MODE}`
  ];

export let claim_x_reseller_client_Id =
  process.env[
    `REACT_APP_CLAIM_X_RESELLER_CLIENT_ID_${process.env.REACT_APP_MODE}`
  ];
export let claim_x_reseller_client_secret =
  process.env[
    `REACT_APP_CLAIM_X_RESELLER_CLIENT_SECRET_${process.env.REACT_APP_MODE}`
  ];

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isAuthenticated = (role) => {
  const token = localStorage.getItem('token');
  const auth_token = localStorage.getItem('auth_token');
  const approved = localStorage.getItem('approved');
  let isRole = false;
  role?.map((item) => {
    if (hasRole(USER_ROLES[item])) {
      isRole = true;
    }
  });

  if (
    !isRole ||
    token == null ||
    token == ' ' ||
    approved == 'false' ||
    !auth_token
  ) {
    localStorage.clear();
    return false;
  }
  return true;
};

export const getValue = (vital, reading1, reading2) => {
  switch (vital) {
    case 'Blood Pressure':
      return reading1.toFixed(1) + '/' + reading2.toFixed(1) + ' mmhg';
    case 'Glucoses':
      return `${reading1} mg/dL`;
    case 'Weight':
      return `${reading1} kgs`;
    case 'Temperature':
      return `${reading1} °F`;
    case 'Spo2':
      return `${reading1} %`;
    case 'Pulse':
      return `${reading1} bpn`;
    case 'Height':
      return `${reading1} cms`;
    default:
      return '';
  }
};

export const headers = () => {
  const token = localStorage.getItem('token');
  const auth_token = localStorage.getItem('auth_token');
  return {
    headers: {
      Authorization: `Token ${token}`,
      'Auth-Token': auth_token,
      'Content-Type': 'application/json'
    }
  };
};

export const getClaimHeaders = () => {
  const token = localStorage.getItem('token');
  const auth_token = localStorage.getItem('auth_token');
  let myHeaders = new Headers();
  myHeaders.append('Authorization', `Token ${token}`);
  myHeaders.append('Auth-Token', auth_token);
  myHeaders.append('accept', '*/*');
  return myHeaders;
};

export const ClaimHeadersWithoutSecret = () => {
  const token = localStorage.getItem('token');
  const authToken = localStorage.getItem('auth_token');
  return {
    headers: {
      Authorization: `Token ${token}`,
      'Auth-Token': authToken
    }
  };
};

export const claimsHeader = () => {
  const auth_token = localStorage.getItem('auth_token');
  return {
    headers: {
      'Auth-Token': auth_token,
      'client-access-key': client_access_key,
      'client-access-secret': client_access_secret
    }
  };
};

export const enrollmentHeader = () => {
  const token = localStorage.getItem('token');
  const auth_token = localStorage.getItem('auth_token');
  return {
    headers: {
      Authorization: `Token ${token}`,
      'X-RESELLER-Client-Id': claim_x_reseller_client_Id,
      'X-RESELLER-Client-Secret': claim_x_reseller_client_secret,
      'Auth-Token': auth_token
    }
  };
};

export const enrollmentHeaderForPdf = () => {
  const token = localStorage.getItem('token');
  const auth_token = localStorage.getItem('auth_token');
  return {
    headers: {
      Accept: `application/json`,
      'Content-Type': `application/pdf`,
      'Auth-Token': auth_token,
      'cache-control': `no-cache`,
      Authorization: `Token ${token}`,
      'X-RESELLER-Client-Id': claim_x_reseller_client_Id,
      'X-RESELLER-Client-Secret': claim_x_reseller_client_secret
    }
  };
};

export const formattedDate = (a) => {
  const d = new Date(a);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return `${day}/${month}`;
};

export const humanize = (str) => {
  if (str == null || str == '') {
    return '';
  }
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

//convert 24 time in to 12 hour
export const timeConvert = (time) => {
  if (time == null || time == '') {
    return '';
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

export const convertTo12HourFormat = (time24hr) => {
  if (time24hr) {
    const [hours, minutes, sec] = time24hr?.split(':');
    let period = 'AM';

    let hour = parseInt(hours, 10);
    if (hour === 0) {
      hour = 12;
    } else if (hour >= 12) {
      hour %= 12;
      period = 'PM';
    }

    return `${hour == '0' ? '00' : hour}:${minutes}:${sec} ${period}`;
  } else return '----';
};
export const formatSeconds = (seconds) => {
  if (seconds) {
    if (seconds >= 60) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  } else return '----';
};

export const hideLastFourCharacters = (input) => {
  if (input == null) return;
  let str = String(input);

  if (str?.length <= 4) {
    return 'X'.repeat(str?.length);
  }

  const lastFourChars = str?.slice(-4);
  const maskedString = 'X'.repeat(input?.length - 4) + lastFourChars;

  return maskedString;
};

let fcmToken = null;

export const getFcmToken = () => fcmToken;
export const setFcmToken = (value) => {
  fcmToken = value;
};
