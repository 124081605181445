import React, { useState } from 'react';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import DocumentCard from '../documentCard';
import DocumentModal from '../documentModal';
import { CLAIM_SERVICE_URL } from 'src/redux/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { headers } from 'src/utils';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { PostApi } from 'src/services/middleware';

function HorizontalScroll({
  label,
  items = [],
  onDocApprove,
  isApprovedBtnPressed,
  isEditable,
  claimId = '',
  setIsReload,
  IO_Reviewer,
  reseller_id,
  isApproved,
  onOpenInNew
}) {
  const [isDocumentModalOpen, toggleDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [file, setFile] = useState('');

  const submitForm = async () => {
    if (file.length < 1) {
      toast.error('Must be Upload Document');
      return 0;
    }
    let form_data = new FormData();
    form_data.append('file', file);
    try {
      const res = await PostApi(
        `${CLAIM_SERVICE_URL}/claim-service/api/docs?referenceType=CLAIM&referenceId=${claimId}&documentTypeId=9&resellerId=${reseller_id}`,
        form_data,
        headers()
      );
      if (res.status == 200) {
        toast.success('File Uploaded Successfully');
        setIsReload(true);
      }
    } catch (err) {
      toast.error('something went wrong');
    } finally {
      setOpenForm(false);
    }
  };

  const imgBinary = (event) => {
    setFile(event.target.files[0]);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  const handleOpenInNew = (document) => {
    onOpenInNew(document);
  };

  return (
    <>
      <Typography variant="subtitle2">
        {label}{' '}
        {label == 'Supporting' && IO_Reviewer ? (
          <Button
            onClick={() => setOpenForm(true)}
            style={{
              marginLeft: '20px',
              backgroundColor: '#00bcd4',
              color: 'white',
              fontWeight: 'bold'
            }}
            size="small"
            variant="outlined"
          >
            Add Document
          </Button>
        ) : (
          ''
        )}
      </Typography>
      <div
        style={{
          marginTop: 8,
          marginBottom: 16,
          overflowX: 'scroll',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {items.map((doc, index) => (
          <DocumentCard
            key={index}
            data={doc}
            viewDocument={(selectedDoc) => {
              setSelectedDocument(selectedDoc);
              toggleDocumentModal(true);
            }}
            isApprovedBtnPressed={isApprovedBtnPressed}
            isApproved={isApproved}
            onOpenInNew={onOpenInNew ? handleOpenInNew : false}
          />
        ))}
      </div>
      {isDocumentModalOpen && (
        <DocumentModal
          open={isDocumentModalOpen}
          handleClose={() => {
            toggleDocumentModal(false);
            setSelectedDocument(null);
          }}
          selectedDocument={selectedDocument}
          onDocApprove={onDocApprove}
          isEditable={isEditable}
          isApproved={isApproved}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openForm}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ width: '300px' }}
          onClose={handleClose}
        >
          Add Document
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Upload Document</Typography>
          <input
            id="outlined-basic"
            size="small"
            variant="outlined"
            type="file"
            accept="image/x-png,image/gif,image/jpeg,application/pdf"
            onChange={imgBinary}
            style={{ width: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              submitForm();
            }}
            size="small"
            variant="outlined"
            style={{ marginLeft: 10 }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            style={{ marginLeft: 10 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HorizontalScroll;
