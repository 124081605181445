import { ageCalculationEPOC } from 'src/services/data';

export const getAge = (birthDate) =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / ageCalculationEPOC);

export const mobileNumberFormate = (mobile) => {
  const regex = /(\+91)/g;
  return `${mobile}`.replace(regex, `\$1-`);
};

export const getDataFromLocal = (CACHE_KEY) => {
  const cachedData = localStorage.getItem(CACHE_KEY);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);

    return parsedData.data;
  } else return false;
};

export const setDataToLocal = (data, CACHE_KEY) => {
  const cachedData = {
    data: data
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
};

export const maskingGovtID = (input) => {
  if (input == null) return null;
  let str = String(input);

  if (str?.length <= 4) {
    return 'X'.repeat(str?.length);
  }

  const lastFourChars = str?.slice(-4);
  const maskedString = 'X'.repeat(input.length - 4) + lastFourChars;

  return maskedString;
};

export const appointmentType = [
  'appointment_created',
  'pharmacist_joined_call',
  'doctor_joined_call',
  'aiqa_center_joined_call'
];

export function customDebounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

export const statusColor = {
  PENDING: '#F1C40F',
  RAISED: '#E67E22',
  IN_PROGRESS: '#E67E22',
  QUERY: '#F1C40F',
  REJECTED: '#E74C3C',
  APPROVED: '#2ECC71',
  SETTELED: '#3498DB'
};
